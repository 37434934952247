import {addDoc, collection, deleteDoc, orderBy, query, updateDoc} from "firebase/firestore";
import {firestoreDB} from "../../services/fireBase";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {useState} from "react";
import {Form, Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {companyConverter} from "../../services/Converters";

export function AddCompany(){
    const navigate = useNavigate();
    const collectionRef = collection(firestoreDB, 'company').withConverter(companyConverter);
    const [values, loading, error] = useCollectionData(collectionRef);
    const [companyToAdd, setCompanyToAdd] = useState({});

    const addCompany = async (companyToAdd) => {
        await addDoc(collectionRef, companyToAdd); // updateDoc instead of add for edit page
        navigate("/company");
    }

    return (
        <div>
            <Form>
                <Form.Label>name:</Form.Label>
                <Form.Control
                    value={companyToAdd.name}
                    onChange={e => setCompanyToAdd({...companyToAdd, name: e.target.value})}/>
                <Form.Label>details:</Form.Label>
                <Form.Control
                    value={companyToAdd.details}
                    onChange={e => setCompanyToAdd({...companyToAdd, details: e.target.value})}/>
                <Form.Label>image:</Form.Label>
                <Form.Control
                    value={companyToAdd.image}
                    onChange={e => setCompanyToAdd({...companyToAdd, image: e.target.value})}/>
                <Form.Label>start date:</Form.Label>
                <Form.Control
                    value={companyToAdd.startDate}
                    onChange={e => setCompanyToAdd({...companyToAdd, startDate: e.target.value})}/>
            </Form>

            <div>
                <button className={"primaryButton"} onClick={async () => {
                    await addCompany(companyToAdd);
                }}>save</button>
            </div>
        </div>
    );


}