import {addDoc, deleteDoc, collection} from "firebase/firestore";
import {firestoreDB} from "../../services/fireBase";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {useNavigate} from "react-router-dom";
import {gameConverter} from "../../services/Converters";
import {DetailsComponent} from "../../components/DetailsComponent";
import {PrimaryButton} from "../../components/PrimaryButton";
import {LinkButton} from "../../components/LinkButton";

export function GameDetails (){
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    let gameId = params.get('id');
    const collectionRef = collection(firestoreDB, 'game').withConverter(gameConverter);
    const [values, loading, error] = useCollectionData(collectionRef);

    let game;
    if (!loading && !error){
        game = values.find(v => v.id === gameId );
    }

    const deleteGame = async () => {
        await deleteDoc(game.ref);
        navigate("/");
    }

    // https://react.dev/reference/react/Fragment
    // <> </> is shorthand for Fragment
    return(<>
            {game &&
                <div className={"d-flex flex-column p-3 gap-2"}>
                    <DetailsComponent data={game}/>

                    <div className={"d-flex flex-row gap-4 "}>
                        <PrimaryButton onClick={async () => {await deleteGame();}}>
                            delete
                        </PrimaryButton>

                        <PrimaryButton onClick={() => navigate(`/games/edit?id=${game.id}`)}>
                            Edit
                        </PrimaryButton>

                        <p className={"date"}> AgeRating: {game.ageRating}</p>
                    </div>
                </div>
            }
        </>

    )
}