import {Footer} from "../../components/navigation/Footer";
import {TopBar} from "../../components/navigation/TopBar/TopBar";
import {Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";

export function Layout(props) {
    const { children } = props;

    return (
        <>
            <Container className={"w-100 mw-100 m-0 mx-0 g-0 gx-0" }>
                <Row className={"w-100 mw-100 m-0 mx-0 g-0 gx-0"}>
                    <Col className={"w-100 mw-100 m-0 mx-0 g-0 gx-0"}>
                        <TopBar className={"w-100 mw-100 m-0 mx-0 g-0 gx-0"}/>
                        <div className={"z-2 position-absolute w-100"}>
                            <div className={"mb-5"}>
                                {children}
                            </div>

                        </div>
                        <Footer className={"w-100 mw-100"}/>
                    </Col>

                </Row>
            </Container>
            <img src={"/games.png"} className={'z-1 w-100 position-absolute'}/>
        </>
    )
}
