
export const gameConverter = {
    toFirestore: function (game) {
        return {
            name: game.name || "",
            details: game.details || "",
            image: game.image || "",
            ageRating: game.ageRating || "",
            company: game.company || ""
        };
    },
    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        return {...data, id: snapshot.id, ref: snapshot.ref}
    }
}

export const companyConverter = {
    toFirestore: function (company) {
        return {
            name: company.name || "",
            details: company.details || "",
            image: company.image || "",
            startDate: company.startDate || ""
        };
    },
    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        return {...data, id: snapshot.id, ref: snapshot.ref}
    }
}