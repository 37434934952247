
import {Link} from "react-router-dom";
import {Card} from "react-bootstrap";


export function CompanyComponent (props){
    const {company} = props;


    const shortenDetail = (detail) => {
        let shortDetail = detail.substring(0, 150);
        let lastSpace = shortDetail.lastIndexOf(" ");
        return shortDetail.substring(0, lastSpace)
    }

    return(
        <Card>
            <h3>{company.name}</h3>
            { company.image && <img className={"Logo"} src={company.image} alt={'image'}/>}

            <p>{shortenDetail(company.details)}...</p>

            <Link to={`/company/details?id=${company.id}`} className={'primaryButton'}> details </Link>
        </Card>
    )
}

// /company/details?name=${company.name}
// dit is een query param
// deze moet je ophalen in de details pagina
// om dan te weten welke company je wilt bekijken
// dus terug companies ophalen, maar enkel die met de juiste naam en dan gegevens tonen