import {Link} from "react-router-dom";
import {Card} from 'react-bootstrap'

export function GamesComponent (props){
    const {games, company} = props;

    const shortenDetail = (detail) => {
        if (!detail || detail.length <= 150) {
            return detail;
        }
        const shortDetail = detail.substring(0, 150);
        const lastSpace = shortDetail.lastIndexOf(" ");
        if (lastSpace > 0) {
            return shortDetail.substring(0, lastSpace);
        } else {
            return detail;
        }
    };

    return(
        <Card>
            <h3>{games.name}</h3>
            { games.image && <img className={'Logo'} src={games.image} alt={'image'}/>}

            <p>{shortenDetail(games.details)}...</p>
            {company &&
                <p>made by:
                    {<Link to={`/company/details?id=${company.id}`}> {company.name} </Link>}
                </p>
            }
            <Link to={`/games/details?id=${games.id}`} className={'primaryButton'}> details </Link>
        </Card>
    )

}