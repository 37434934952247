import {addDoc, deleteDoc, collection} from "firebase/firestore";
import {firestoreDB} from "../../services/fireBase";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {useNavigate} from "react-router-dom";
import {companyConverter} from "../../services/Converters";
import {DetailsComponent} from "../../components/DetailsComponent";
import {PrimaryButton} from "../../components/PrimaryButton";

export function CompanyDetails (){
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    let companyId = params.get('id');
    const collectionRef = collection(firestoreDB, 'company').withConverter(companyConverter);
    const [values, loading, error] = useCollectionData(collectionRef);

    let company;
    if (!loading && !error){
        company = values.find(v => v.id === companyId );
    }

    const deleteCompany = async () => {
        await deleteDoc(company.ref);
        navigate("/company");
    }

    return(<>
            {company &&
                <div className={"d-flex flex-column p-3 gap-2"}>
                    <DetailsComponent data={company}/>

                    <PrimaryButton className={"primaryButton w-10"} onClick={async () => {
                        await deleteCompany();
                         }}>
                    delete
                    </PrimaryButton>

                    <PrimaryButton onClick={() => navigate(`/games/edit?id=${company.id}`)}>
                        Edit
                    </PrimaryButton>
                </div>
            }
        </>

    )
}