import {Form} from "react-bootstrap";

export function SearchComponent({search, setSearch}){
    return (
        <>
            <Form.Label className={"align-self-center"}>Search</Form.Label>
            <Form.Control
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
        </>
    );
}