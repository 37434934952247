import {createContext, useEffect, useState} from "react";

export const VisitedContext = createContext();

export const VisitedProvider = ({children}) => {
    const [visits, setVisits] = useState(0);

    useEffect(() => {
        const storedVisits = localStorage.getItem('visits');
        if (storedVisits) {
            setVisits(Number(storedVisits));
        }
    }, [setVisits]);

    useEffect(() => {
        const storedVisits = localStorage.getItem('visits');
        if (storedVisits) {
            localStorage.setItem('visits', Number(storedVisits) + 1 );
        } else {
            localStorage.setItem('visits', 1);
        }
    }, []);

    return <VisitedContext.Provider value={{ visits, setVisits }}>
        {children}
    </VisitedContext.Provider>;
}