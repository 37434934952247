import "./services/fireBase"
import './App.css';
import {Games} from "./pages/Games/Games";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {Layout} from "./pages/Layout/Layout";
import {Company} from "./pages/Company/Company";
import {CompanyDetails} from "./pages/Company/CompanyDetails";
import {AddCompany} from "./pages/Company/AddCompany";
import {AddGames} from "./pages/Games/AddGames";
import {EditGame} from "./pages/Games/EditGame";
import {GameDetails} from "./pages/Games/GameDetails";
import {WelcomePage} from "./pages/Welcome/WelcomePage";
import { PageHistoryProvider} from "./context/LastPageHistoryContext";
import {EditCompany} from "./pages/Company/EditCompany";


function App() {
    return (
        <>
            <Router>
                <PageHistoryProvider>
                    <Routes>
                        <Route path="/games" element={<Layout> <Games/> </Layout> } />
                        <Route path="/company" element={<Layout> <Company/>  </Layout>} />
                        <Route path="/company/details" element={<Layout> <CompanyDetails/>  </Layout>} />
                        <Route path={"/company/edit"} element={<Layout> <EditCompany/> </Layout>} />
                        <Route path="/company/add" element={<Layout> <AddCompany/>  </Layout>} />
                        <Route path="/games/add" element={<Layout> <AddGames/>  </Layout>} />
                        <Route path="/games/details" element={<Layout> <GameDetails/>  </Layout>} />
                        <Route path="/games/edit" element={<Layout> <EditGame/>  </Layout>} />
                        <Route path="/" element={<Layout> <WelcomePage/>  </Layout>} />
                    </Routes>
                </PageHistoryProvider>
            </Router>
        </>
    )
}

export default App;
