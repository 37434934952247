import { addDoc, collection, updateDoc, doc } from "firebase/firestore";
import { firestoreDB } from "../../services/fireBase";
import {useEffect, useState} from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {useCollectionData} from "react-firebase-hooks/firestore";

import {gameConverter} from "../../services/Converters";
import {PrimaryButton} from "../../components/PrimaryButton";

export function EditGame(){
    const navigate = useNavigate();
    const collectionRef = collection(firestoreDB, 'game').withConverter(gameConverter);
    const params = new URLSearchParams(window.location.search); // url name parameter
    let gameId = params.get('id');
    const [values, loading, error] = useCollectionData(collectionRef);
    const [gameToUpdate, setGameToUpdate] = useState({});

    useEffect(() => {
        if (!loading && !error){
            let game = values.find(v => v.id === gameId );
            setGameToUpdate(game);
        }
    }, [loading, error, values, gameId, setGameToUpdate])

    const updateGame = async (gameToUpdate) => {
        const docRef = doc(firestoreDB, 'game', gameToUpdate.id);
        await updateDoc(docRef, gameToUpdate); // updateDoc instead of add for edit page
        navigate(`/games/details?id=${gameToUpdate.id}`);
    }

    return (
        <div className={'flex flex-col p-2 gap-2'}>
            <Form className={"flex flex-col gap-2"}>

                <Form.Group className="flex flex-row gap-1 p-1" controlId="formName">
                    <Form.Label className={"p-1"}>Name:</Form.Label>
                    <Form.Control
                        type="text"
                        className={"border p-1 "}
                        placeholder={"name"}
                        value={gameToUpdate.name}
                        onChange={e => setGameToUpdate({...gameToUpdate, name: e.target.value})}/>
                </Form.Group>

                <Form.Group className="flex flex-row gap-1" controlId="formDetails">
                    <Form.Label className={"p-1"}>Details:</Form.Label>
                    <Form.Control
                        type="text"
                        className={"border p-1 "}
                        placeholder={"details"}
                        value={gameToUpdate.details}
                        onChange={e => setGameToUpdate({...gameToUpdate, details: e.target.value})}/>
                </Form.Group>

                <Form.Group className="flex flex-row gap-1" controlId="formImage">
                    <Form.Label className={"p-1"}>Image (base64 encoded):</Form.Label>
                    <Form.Control
                        type="text"
                        className={"border p-1 "}
                        placeholder={"image base64..."}
                        value={gameToUpdate.image}
                        onChange={e => setGameToUpdate({...gameToUpdate, image: e.target.value})}/>
                </Form.Group>

                <Form.Group className="flex flex-row gap-1" controlId="formAge">
                    <Form.Label className={"p-1"}>Age Rating:</Form.Label>
                    <Form.Control
                        type="text"
                        className={"border p-1 "}
                        placeholder={"18+"}
                        value={gameToUpdate.ageRating}
                        onChange={e => setGameToUpdate({...gameToUpdate, ageRating : e.target.value})}/>
                </Form.Group>

                <Form.Group className="flex flex-row gap-1" controlId="formCompany">
                    <Form.Label className={"p-1"}>Company:</Form.Label>
                    <Form.Control
                        type="text"
                        className={"border p-1 "}
                        placeholder={"company"}
                        value={gameToUpdate.company}
                        onChange={e => setGameToUpdate({...gameToUpdate, company : e.target.value})}/>
                </Form.Group>
            </Form>

            <div>
                <PrimaryButton onClick={async () => {await updateGame(gameToUpdate);}}>
                    save
                </PrimaryButton>
            </div>
        </div>
    );


}

