import {CompanyComponent} from "../../components/CompanyComponent";
import {collection} from "firebase/firestore";
import {firestoreDB} from "../../services/fireBase";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {Link} from "react-router-dom";
import {useState} from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
import {companyConverter} from "../../services/Converters";
import {SearchComponent} from "../../components/SearchComponent";
import {LinkButton} from "../../components/LinkButton";


export function Company (){
    const collectionRef = collection(firestoreDB, 'company').withConverter(companyConverter);
    const [values, loading, error] = useCollectionData(collectionRef);

    return (
        <Container>
            <Row>
                <Col>
                    <Search company={values} className={'search'} />
                </Col>
            </Row>
        </Container>
    );
}


function Search(props) {
    const {company} = props;
    const [search, setSearch] = useState("");

    return (
        <div className="d-flex flex-column w-100 p-3 gap-3">
            <div className="d-flex flex-row">
                <Form className={"d-flex flex-row gap-2 height-40px"}>
                    <SearchComponent search={search} setSearch={setSearch}/>
                    <LinkButton link={'/company/add'} text={'add new company'}/>
                </Form>
            </div>
            <div className="d-flex flex-wrap flex-row gap-3">
                {Array.isArray(company) &&
                    company
                        .filter((company) => company && company.name && // Check for existence
                            company.name.toLowerCase().includes(search.toLowerCase())
                        )
                        .map((filteredCompany) => (
                            <CompanyComponent key={filteredCompany.id} company={filteredCompany} />
                        ))}
            </div>
        </div>
    );
}


