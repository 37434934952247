/* eslint-disable no-restricted-globals */
var CACHE_NAME = 'pwa-game';
var urlsToCache = [
    '/', '/games', '/company', '/company/details', '/company/add', '/games/add', '/games/details'
];

// Install a service worker
self.addEventListener('install', event => {
    // Perform install steps
    event.waitUntil(
        caches.open(CACHE_NAME)
            .then(function(cache) {
                console.log('Opened cache');
                return cache.addAll(urlsToCache);
            })
    );
});

// Cache and return requests
// self.addEventListener('fetch', event => {
//     event.respondWith(
//         caches.match(event.request)
//             .then(function(response) {
//                     // Cache hit - return response
//                     if (response) {
//                         return response;
//                     }
//                     return fetch(event.request);
//                 }
//             )
//     );
// });
// netwerk first , fallback to cache
self.addEventListener('fetch', event => {
    if (event.request.mode === 'navigate') {
        event.respondWith(
            fetch(event.request).then(fetchedResponse => {
                return caches.open(CACHE_NAME).then(cache => {
                    cache.put(event.request, fetchedResponse.clone());
                    return fetchedResponse;
                });
            }).catch(() => {
                return caches.match(event.request);
            })
        );
    }
});
// Update a service worker
self.addEventListener('activate', event => {
    var cacheWhitelist = ['pwa-task-manager'];
    event.waitUntil(
        caches.keys().then(cacheNames => {
            return Promise.all(
                cacheNames.map(cacheName => {
                    if (cacheWhitelist.indexOf(cacheName) === -1) {
                        return caches.delete(cacheName);
                    }
                })
            );
        })
    );
});
