export function DetailsComponent({data}) {
    return (
        <>
            <h3 >{data.name}</h3>
            { data.startDate ??<p className={"date"}>{data.startDate}</p>}
            <div className={"d-flex flex-row gap-4 "}>
                {data.image && <img className={"rounded detailsLogo"} src={data.image} alt={'image'}/>}
                <div className={"bg-dark"}>
                <p>{data.details}</p>
                </div>
            </div>
        </>
    )
}