import {useNavigate} from "react-router-dom";
import {collection, doc, updateDoc} from "firebase/firestore";
import {firestoreDB} from "../../services/fireBase";
import {companyConverter} from "../../services/Converters";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {PrimaryButton} from "../../components/PrimaryButton";

export function EditCompany(){
    const navigate = useNavigate();
    const collectionRef = collection(firestoreDB, 'game').withConverter(companyConverter);
    const params = new URLSearchParams(window.location.search); // url name parameter
    let companyId = params.get('id');
    const [values, loading, error] = useCollectionData(collectionRef);
    const [companyToUpdate, setComanyToUpdate] = useState({});

    useEffect(() => {
        if (!loading && !error){
            let company = values.find(v => v.id === companyId );
            setComanyToUpdate(company);
        }
    }, [loading, error, values, companyId, setComanyToUpdate])

    const updateCompany = async (companyToUpdate) => {
        const docRef = doc(firestoreDB, 'game', companyToUpdate.id);
        await updateDoc(docRef, companyToUpdate); // updateDoc instead of add for edit page
        navigate(`/company/details?id=${companyToUpdate.id}`);
    }

    return (
        <div className={'flex flex-col p-2 gap-2'}>
            <Form className={"flex flex-col gap-2"}>

                <Form.Group className="flex flex-row gap-1 p-1" controlId="formName">
                    <Form.Label className={"p-1"}>Name:</Form.Label>
                    <Form.Control
                        type="text"
                        className={"border p-1 "}
                        placeholder={"name"}
                        value={companyToUpdate.name}
                        onChange={e => setComanyToUpdate({...companyToUpdate, name: e.target.value})}/>
                </Form.Group>

                <Form.Group className="flex flex-row gap-1" controlId="formDetails">
                    <Form.Label className={"p-1"}>Details:</Form.Label>
                    <Form.Control
                        type="text"
                        className={"border p-1 "}
                        placeholder={"details"}
                        value={companyToUpdate.details}
                        onChange={e => setComanyToUpdate({...companyToUpdate, details: e.target.value})}/>
                </Form.Group>

                <Form.Group className="flex flex-row gap-1" controlId="formImage">
                    <Form.Label className={"p-1"}>Image (base64 encoded):</Form.Label>
                    <Form.Control
                        type="text"
                        className={"border p-1 "}
                        placeholder={"image base64..."}
                        value={companyToUpdate.image}
                        onChange={e => setComanyToUpdate({...companyToUpdate, image: e.target.value})}/>
                </Form.Group>

                <Form.Group className="flex flex-row gap-1" controlId="formImage">
                    <Form.Label className={"p-1"}>Founding Date:</Form.Label>
                    <Form.Control
                        type="text"
                        className={"border p-1 "}
                        placeholder={"image base64..."}
                        value={companyToUpdate.startDate}
                        onChange={e => setComanyToUpdate({...companyToUpdate, startDate: e.target.value})}/>
                </Form.Group>

            </Form>

            <div>
                <PrimaryButton onClick={async () => {await updateCompany(companyToUpdate);}}>
                    save
                </PrimaryButton>
            </div>
        </div>
    );


}
