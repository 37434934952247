import logo from './logo.png'
import {NavLink} from "../../NavLink";
import {useContext} from "react";
import {PageHistoryContext} from "../../../context/LastPageHistoryContext";

export function TopBar (props){
    const {pageHistory} = useContext(PageHistoryContext);
    console.log(pageHistory);
    console.log(pageHistory.slice(-2, -1));
    return (
        <nav className={"d-flex flex-row gap-4 bg-black"}>
            <NavLink to="/">
                <img className={"Logo w-50"} src={logo} alt={"logo"}/>
            </NavLink>
            <NavLink to="/">
                Home
            </NavLink>

            <NavLink to="/games">
                Games
            </NavLink>

            <NavLink to="/company">
                Companies
            </NavLink>

            <NavLink to={pageHistory.length > 2
                ? pageHistory.slice(-2, -1)
                : null}>
                previous page
            </NavLink>
        </nav>
    )
}