import {useContext, useEffect, useState} from "react";
import {PrimaryButton} from "../../components/PrimaryButton";
import {useNavigate} from "react-router-dom";
import {VisitedContext} from "../../context/VisitedContext";

export function WelcomePage(){
    const navigate = useNavigate();
    const { visits, setVisits } = useContext(VisitedContext);

    return(<>
        <div className={"z-1 position-absolute w-100"}>
            <div className={"text-center"}>
                <h1 className={"text-white"}>Welcome</h1>
                <div className={"flex flex-col gap-2 text-white"}>
                    <p>hey ik ben xeno dit is mijn react project voor school. </p>
                    <p>je hebt mijn website al {visits} keer bezocht </p>

                    <PrimaryButton onClick={() => navigate("/games")}>Games</PrimaryButton>
                </div>
            </div>
        </div>
    </>);
}