import {GamesComponent} from "../../components/GamesComponent";
import {collection} from "firebase/firestore";
import {firestoreDB} from "../../services/fireBase";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {Link} from "react-router-dom";
import {Col, Container, Form, Row} from "react-bootstrap";
import {useState} from "react";
import {companyConverter, gameConverter} from "../../services/Converters";
import {SearchComponent} from "../../components/SearchComponent";
import {LinkButton} from "../../components/LinkButton";


export function Games(props) {
    const collectionRef = collection(firestoreDB, 'game').withConverter(gameConverter);
    const [values, loading, error] = useCollectionData(collectionRef);

    if (error)
        console.log(error);

    // gebruik loading variable om null te returnen zolang deze true is, anders render je 2 keer content, een keer zonder games en een keer met games
    if (loading)
        return null;

    return (
        <Container>
            <Row>
                <Col className={"d-flex flex-column"}>
                    <div>
                        <Search games={values} />
                    </div>

                </Col>
            </Row>
        </Container>
    );
}

function Search(props) {
    const { games } = props;
    const [search, setSearch] = useState("");

    const collectionRef = collection(firestoreDB, 'company').withConverter(companyConverter);
    const [companies, loading, error] = useCollectionData(collectionRef);

    if (loading)
        return null

    return (
        <div className="d-flex flex-column w-100 p-3 gap-3">
            <div className="d-flex flex-row">
                <Form className={"d-flex flex-row gap-2 height-40px"}>
                    <SearchComponent search={search} setSearch={setSearch}/>
                    <LinkButton link={'/games/add'} text={'add new game'}/>
                </Form>
            </div>
            <div className="d-flex flex-row gap-3 flex-wrap">
                {Array.isArray(games) &&
                    games
                        .filter((games) => games && games.name && // Check for existence
                            games.name.toLowerCase().includes(search.toLowerCase())
                        )
                        .map((filteredGame) => (
                            <GamesComponent key={filteredGame.id} games={filteredGame} company={companies.find(c => c.name === filteredGame.company)} />
                        ))}
            </div>
        </div>
    );
}

