import { addDoc, collection } from "firebase/firestore";
import { firestoreDB } from "../../services/fireBase";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {useCollectionData} from "react-firebase-hooks/firestore";

import {gameConverter} from "../../services/Converters";
import {PrimaryButton} from "../../components/PrimaryButton";

export function AddGames(){
    const navigate = useNavigate();
    const collectionRef = collection(firestoreDB, 'game').withConverter(gameConverter);
    const [values, loading, error] = useCollectionData(collectionRef);
    const [gameToAdd, setGameToAdd] = useState({});

    const addGame = async (gameToAdd) => {
        await addDoc(collectionRef, gameToAdd); // updateDoc instead of add for edit page
        navigate("/");
    }

    return (
        <div>
            <Form className={"flex flex-col gap-2"}>

                <Form.Group className="flex flex-row gap-1 p-1" controlId="formName">
                    <Form.Label className={"p-1"}>Name:</Form.Label>
                    <Form.Control
                        type="text"
                        className={"border p-1 "}
                        placeholder={"name"}
                        value={gameToAdd.name}
                            onChange={e => setGameToAdd({...gameToAdd, name: e.target.value})}/>
                </Form.Group>

                <Form.Group className="flex flex-row gap-1" controlId="formDetails">
                    <Form.Label className={"p-1"}>Details:</Form.Label>
                    <Form.Control
                        type="text"
                        className={"border p-1 "}
                        placeholder={"details"}
                        value={gameToAdd.details}
                        onChange={e => setGameToAdd({...gameToAdd, details: e.target.value})}/>
                </Form.Group>

                <Form.Group className="flex flex-row gap-1" controlId="formImage">
                    <Form.Label className={"p-1"}>Image (base64 encoded):</Form.Label>
                    <Form.Control
                        type="text"
                        className={"border p-1 "}
                        placeholder={"image base64..."}
                        value={gameToAdd.image}
                        onChange={e => setGameToAdd({...gameToAdd, image: e.target.value})}/>
                </Form.Group>

                <Form.Group className="flex flex-row gap-1" controlId="formAge">
                    <Form.Label className={"p-1"}>Age Rating:</Form.Label>
                    <Form.Control
                        type="text"
                        className={"border p-1 "}
                        placeholder={"18+"}
                        value={gameToAdd.ageRating}
                        onChange={e => setGameToAdd({...gameToAdd, ageRating : e.target.value})}/>
                </Form.Group>

                <Form.Group className="flex flex-row gap-1" controlId="formCompany">
                    <Form.Label className={"p-1"}>Company:</Form.Label>
                    <Form.Control
                        type="text"
                        className={"border p-1 "}
                        placeholder={"company"}
                        value={gameToAdd.company}
                        onChange={e => setGameToAdd({...gameToAdd, company : e.target.value})}/>
                </Form.Group>
            </Form>

            <div>
                <PrimaryButton onClick={async () => {await addGame(gameToAdd);}}>
                    save
                </PrimaryButton> 
            </div>
        </div>
    );


}

