import {createContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

export const PageHistoryContext = createContext();

export const PageHistoryProvider = ({children}) => {
    const [pageHistory, setPageHistory] = useState([]);
    const location = useLocation();

    useEffect(() => {
        setPageHistory([...pageHistory, location.pathname]);
    }, [location]);

    return <PageHistoryContext.Provider value={{ pageHistory, setPageHistory }}>
        {children}
    </PageHistoryContext.Provider>;
}